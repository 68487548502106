import { assert } from "@faro-lotv/foundation";

/**
 * Asserts that the given value is truthy.
 *
 * @param value The value to assert.
 * @param message Optional error message.
 * @returns value with NonNullable type.
 * @throws {Error} if value is falsy.
 *
 * @example `getFileExtension(assertValue(filenameOrUndefined));`
 */
export function assertValue<T>(value: T, message?: string): NonNullable<T> | never {
  assert(value, message);
  return value;
}

/**
 * Asserts that predicate(value) is truthy.
 *
 * @param value The value to assert.
 * @param predicate The predicate for which to check `predicate(value)`.
 * @param message Optional error message.
 * @returns value
 * @throws {Error} if predicate(value) is falsy.
 *
 * @example `const rootEntity = assertPredicate(hopefullyRootEntity, isRootEntity) as RootEntity;`;
 */
export function assertPredicate<T>(value: T, predicate: (val: T) => unknown, message?: string): T | never {
  assert(predicate(value), message);
  return value;
}
